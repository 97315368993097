@use 'mixins';

%-override-icon-margin {
    margin: 0 var(--smallPadding) var(--smallPadding) 0;
}

%-override-icon-style {
    margin: initial;
    padding: var(--smallPadding);
    background: transparent;
    border: 1px solid rgb(var(--invertedBorder));
    height: calc(1.5em - 2px);
}

.SocialLinkBlock {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .list-item {
        a {
            @extend %-override-icon-margin;

            :global(.Logo) {
                @extend %-override-icon-style;

                [data-theme='br'] & {
                    background: rgb(var(--lightSectionBack));
                    color: rgb(var(--lighterTextColor));
                }

                [data-theme='gp'] & {
                    background: rgba(var(--lighterTextColor), 0.2);
                }

                [data-theme='br'] &,
                [data-theme='gp'] & {
                    border: initial;
                }

                svg,
                svg g,
                svg path {
                    fill: rgb(var(--invertedTextColor));

                    [data-theme='br'] & {
                        fill: rgb(var(--lighterTextColor));
                    }
                }
            }
        }
    }

    @include mixins.responsive(m, above) {
        justify-content: flex-start;
    }
}
