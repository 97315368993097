@use 'mixins';

.BottomBar {
    display: flex;
    align-items: center;
    margin: var(--largePadding) 0 0 0;
    padding: var(--standardPadding) var(--standardPadding) var(--hugePadding) var(--standardPadding);
    background: rgb(var(--darkestBackground));

    h4 {
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        color: rgb(var(--lighterTextColor));
        font-size: 1em;
    }

    svg path {
        fill: rgb(var(--lighterTextColor));
    }

    .content-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        color: rgb(var(--lighterTextColor));

        :global(svg path#pxr-light) {
            fill: rgb(var(--lighterTextColor));
        }
    }

    .network-platforms {
        display: flex;
        align-items: end;
        flex-direction: row;
        margin: var(--largePadding) auto var(--standardPadding) auto;
    }

    @include mixins.responsive(m, above) {
        padding: var(--tinyPadding) 0;

        .content-wrapper {
            flex-direction: row;
            height: 2em;
        }

        .network-platforms {
            flex: 0;
            flex-direction: row;
            justify-content: space-between;
            margin: 0 0 0 auto;
        }
    }
}
