.PlatformLogoList {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    width: min-content;

    .list-item {
        display: flex;
        flex: 1;
        position: relative;
        transition: all 0.2s ease-in-out;

        &:not(:last-child) {
            margin-right: var(--mediumPadding);
        }

        &:hover {
            filter: brightness(2);
        }

        a {
            align-items: center;
            justify-content: center;
            position: relative;
            width: 100%;
            height: 100%;
        }
    }
}
