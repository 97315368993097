.PXRLogo {
    display: inline-block;
    position: relative;
    width: auto;
    margin: auto var(--mediumPadding) auto var(--standardPadding);
    transition: all 0.2s ease-in-out;

    &:hover {
        filter: brightness(2);
    }

    svg {
        width: auto;
        height: 1.25em;
    }
}
